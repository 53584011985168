.shows-container {
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  align-items: center;
  justify-content: flex-start;
  table-layout: fixed;
}

.show {
  box-sizing: border-box;
  max-width: 1000px;
  padding: 0 10px;
  border-style: ridge;
  border-color: purple;
  border-left-width: 10px;
  border-right-width: 10px;
  border-top-width: 0;
  border-bottom-width: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.show-first {
  border-top-width: 10px;
}

.shows-head {
  width: 100%;
  text-align: center;
}

.show-avatar-box {
  height: 200px;
  width: 200px;
  padding: 0 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  flex-shrink: 0;
}

.show-avatar {
  max-width: 100%;
  max-height: 100%;
}

.show-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 1;
  flex-grow: 1;
  padding: 10px 0px;
}

.show-content {
  margin-top: 8px;
  margin-bottom: 8px;
}

.show-title {
  margin-top: 8px;
  margin-bottom: 2px;
}

.show a {
  text-decoration: underline white;
}

@media (max-width: 500px) {
  .show-avatar-box {
    max-width: 100px;
    min-width: 100px;
    padding: 0;
  }
}
