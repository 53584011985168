.about-container {
  display: flex;
  flex-wrap: none;
  align-items: center;
  flex-direction: column;
}

.about-content {
  display: flex;
  max-width: 600px;
  align-items: center;
  justify-content: center;
}

.about-content > p {
  text-align: justify;
}

.about-content a {
  text-decoration: underline white;
}
