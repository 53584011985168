body {
  font-family: 'Courier New', monospace;
  background-color: black;
  color: white;
  background-image: url(https://deadbeatrad.io/2lav.gif); /* TODO: changing this to a relative path requires some webpack configuration. We can handle this later. */
}

a {
  color: white;
  text-decoration: none;
}

.audio-row {
  display: flex;
  flex-direction: row;
  align-items:center;
}

.audio-descriptor {
  margin-left: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  max-lines: 1;
}

img[src*="counter.websiteout"] {
  position: relative;
  left: 50%;
  transform: translate(-90px, 0);
  margin: 10px;
}

h1, h2 {
  font-weight: 400;
}
