.home-container {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.center-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.centerfold {
  margin: 30px;
  height: 110px;
  width: 100px;
}

@media (max-width: 500px) {
  .centerfold {
    height: 220px;
    width: 200px;
  }
  .centerfold-framing {
    display: none;
  }
}
